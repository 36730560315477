import React, { Component } from 'react'
import { Helmet } from "react-helmet"

class Map extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="mapcontainer"></div>
      </React.Fragment>
    )
  }
}

export default Map
